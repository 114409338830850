import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "@components/layout";
import Seo from "@components/seo";

import TopNav from "@components/topNav";
import Footer from "@components/footer";

import {
  AcademicCapIcon,
  BadgeCheckIcon,
  CashIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
  SaveAsIcon,
} from "@heroicons/react/outline";

const projects = [
  {
    title: "Evidence-based",
    href: "#",
    description:
      "We are committed to providing reliable, accurate, and high-quality information on HIV and sexual health. We use the latest evidence to inform our content and programmatic priorities.",
    imageUrl: "/values/evidence-based.png",
  },
  {
    title: "Evidence-based",
    href: "#",
    description:
      "We are committed to providing reliable, accurate, and high-quality information on HIV and sexual health. We use the latest evidence to inform our content and programmatic priorities.",
    imageUrl: "hero-boost-2.jpg",
  },
  {
    title: "Evidence-based",
    href: "#",
    description:
      "We are committed to providing reliable, accurate, and high-quality information on HIV and sexual health. We use the latest evidence to inform our content and programmatic priorities.",
    imageUrl: "hero-boost-2.jpg",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AboutPage = () => {
  return (
    <div>
      <div className="relative bg-lime overflow-hidden">
        <div className="max-w-6xl mx-auto">
          <div className="relative z-10 pb-8 bg-lime sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidde pl-20 lg:block absolute right-0 inset-y-0 h-full w-56 text-lime transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 180 585"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <polygon
                  id="Path"
                  fill="#A5CF07"
                  fillRule="nonzero"
                  points="0.296038107 585 58.6111495 585 179.266137 296.235761 60.0246807 0 0.296038107 0"
                ></polygon>
              </g>
            </svg>

            <TopNav theme={"sunset"} />

            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left pb-16"> {/* Padding botom if no video button */}
                {/* <h1 className="text-4xl tracking-tight font-bold font-heading text-marineDark sm:text-5xl md:text-6xl"> */}
                <h1 className="text-4xl tracking-loose font-bold text-marineDark sm:text-5xl md:text-5xl font-heading">

                    About us
 
                  {/* <span className="block text-indigo-600 xl:inline"></span> */}
                </h1>
                {/* <p className="mt-3 text-base font-heading text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"> */}
                <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  We are an international charity using digital communications
                  to build health literacy on HIV and sexual health.
                </p>


              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <StaticImage
            src="../images/hero-about.jpeg"
            className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
            alt="Avert"
            placeholder="blurred"
           layout="fullWidth"
            // width={2000}
            // height={1125}
          />
        </div>
      </div>
      {/* <div className="relative bg-gray-50 pt-10 pb-6 px-4 sm:px-6 lg:pt-10 lg:pb-6 lg:px-8">
        <div className="relative max-w-7xl mx-auto"></div>
      </div> */}

      <div className="">
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                {/* <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
                      Our Story
                    </span> */}
                <span className="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl font-heading">
                  Our Story
                </span>
              </h1>
              <p className="mt-8 text-xl text-fossil leading-8">
                With over 30 years’ experience, we are a trusted provider of
                accessible, accurate and actionable content and resources that
                support informed choices. Our work supports global efforts to
                end AIDS and achieve the Sustainable Development Goal for
                Health.
              </p>
            </div>
            <div className="mt-6 prose prose-indigo prose-lg text-fossil mx-auto">
              <p>
                Every year, we engage with millions of individuals, community
                health workers and primary health practitioners living in areas
                of greatest need. We use our understanding of HIV and sexual
                health; our expertise in digital marketing, communications and
                behaviour change; and our network of partnerships, to increase
                their knowledge, skills and confidence and their ability to act.
              </p>

              <figure>
                <img
                  className="w-full rounded-lg"
                  src="https://images.unsplash.com/photo-1556740758-90de374c12ad?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                  alt=""
                  // width={1310}
                  // height={873}
                />
                {/* <figcaption>
                  Put a nice team photo set in Platform9 here
                </figcaption> */}
              </figure>
              <h2 className="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl font-heading">Our vision, mission and values</h2>
         
                 
          

              <div className="mt-6 prose prose-indigo prose-lg text-fossil mx-auto">
              <p>
                Our vision is a world with no new HIV infections, where people
                make empowered sexual health choices, and where those living
                with HIV do so with dignity, good health and equality.
              </p>
              <p>
                Our mission is to increase health literacy on HIV and sexual
                health, among those most affected in areas of greatest need, in
                order to reduce new infections and improve health and
                well-being.
              </p>
              {/* <blockquote> */}
              <p>
                We believe in individuals’ inalienable right to health, and
                their right to make informed choices around their sexual health.
                We aim for everything we do, and how we do it, to be underpinned
                by our set of core values.
              </p>
              {/* </blockquote> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-lime">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="pb-0 pt-12 text-3xl text-leading font-bold text-white font-heading">
            Our core values
          </h2>
        </div>
        <div className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none bg-lime">
            {/* 1 */}
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  src="/values/evidence-based.svg"
                  className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                  //layout="fullWidth"
                  // width={1600}
                  // height={874}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900 font-heading">
                    Evidence-based
                  </p>
                  <p className="mt-3 text-base text-fossil">
                    We are committed to providing reliable, accurate, and
                    high-quality information on HIV and sexual health. We use
                    the latest evidence to inform our content and programmatic
                    priorities.
                  </p>
                </div>
              </div>
            </div>

            {/* 2 */}
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  src="/values/responsive.svg"
                  className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                 //layout="fullWidth"
                  // width={1600}
                  // height={874}
                />
               
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900 font-heading">
                    Responsive
                  </p>
                  <p className="mt-3 text-base text-fossil">
                    We adapt our approach to respond to changing trends in the
                    HIV epidemic and in how our target audiences access and
                    consume information.
                  </p>
                </div>
              </div>
            </div>

            {/* 3 */}
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  src="/values/trustworthy.svg"
                  className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                 //layout="fullWidth"
                  // width={1600}
                  // height={874}
                />
          
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900 font-heading">
                    Trustworthy
                  </p>
                  <p className="mt-3 text-base text-fossil">
                    We have more than 30 years’ experience of working in the HIV
                    response. We are recognised and accredited as a provider of
                    accurate and reliable information.
                  </p>
                </div>
              </div>
            </div>

            {/* 4 */}
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  src="/values/creative.svg"
                  className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                 //layout="fullWidth"
                  width={1600}
                  height={874}
                />
                
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900 font-heading">
                    Creative
                  </p>
                  <p className="mt-3 text-base text-fossil">
                    We understand the power of new approaches to bring about
                    change. We focus on solutions and think creatively to
                    continually improve our work and bring new insights to the
                    sector.
                  </p>
                </div>
              </div>
            </div>
            {/* 5 */}
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  src="/values/people-oriented.svg"
                  className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                 //layout="fullWidth"
                  // width={1600}
                  // height={874}
                />
              
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900 font-heading">
                    People-oriented
                  </p>
                  <p className="mt-3 text-base text-fossil">
                    We put people at the centre of what we do and how we do it –
                    supporting, valuing, engaging and involving them. We aim to
                    be accessible, inclusive and friendly in everything we do.
                  </p>
                </div>
              </div>
            </div>
            {/* 6 */}
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  src="/values/collaborative.svg"
                  className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                 //layout="fullWidth"
                  // width={1600}
                  // height={874}
                />
                
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900 font-heading">
                    Collaborative
                  </p>
                  <p className="mt-3 text-base text-fossil">
                    We believe by working in partnership we can pool skills and
                    resources, share learning, and have greater impact.
                  </p>
                </div>
              </div>
            </div>
            {/* 7 */}
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  src="/values/sex-positive.svg"
                  className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                 //layout="fullWidth"
                  // width={1600}
                  // height={874}
                />
              
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900 font-heading">
                    Sex-positive
                  </p>
                  <p className="mt-3 text-base text-fossil">
                    We believe in a sex-positive approach to sexual health and
                    rights that recognises and celebrates sexual pleasure and
                    sexual diversity, prioritises personal agency, and is free
                    of judgment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutPage;
